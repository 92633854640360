import React from 'react';

import cx from 'classnames';
import { getImageData } from 'lib/get-image-data';
import Image from 'lib/image-container';
import { throttle } from 'lib/throttle';
import useScrollbarWidth from 'lib/use-scrollbar-width';

type HeroImageProps = {
    aspectRatio?: number;
    overlay?: React.ReactNode;
    isFullScreen?: boolean;
    hasFrontFilter?: boolean;
    priority?: boolean;
    imagePortrait: {
        src: string;
        objectPosition?: string;
        blurDataUrl?: string;
    };
    imageLandscape: {
        src: string;
        objectPosition?: string;
        blurDataUrl?: string;
    };
};

const HeroImage: React.FC<HeroImageProps> = (props) => {
    const {
        aspectRatio = 1440 / 700,
        overlay,
        imagePortrait,
        imageLandscape,
        isFullScreen,
        hasFrontFilter,
        priority,
    } = props;

    const scrollbarWidth = useScrollbarWidth(true);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const handleImageLoaded = () => setIsLoaded(true);
    const divRef = React.useRef<HTMLDivElement>(null);
    const [orientation, setOrientation] = React.useState<'portrait' | 'landscape' | null>(null);

    const className = cx({
        'hero-image': true,
        'hero-image--full-screen': isFullScreen,
        'hero-image--front-filter': hasFrontFilter,
    });

    const updateOrientation = () => {
        if (divRef.current) {
            setOrientation(
                divRef.current.offsetHeight >= divRef.current.offsetWidth
                    ? 'portrait'
                    : 'landscape',
            );
        }
    };

    const imageSrc = orientation === 'portrait' ? imagePortrait.src : imageLandscape.src;
    let imageObjectPosition = 'center';
    if (orientation === 'portrait' && imagePortrait.objectPosition) {
        imageObjectPosition = imagePortrait.objectPosition;
    }
    if (orientation !== 'portrait' && imageLandscape.objectPosition) {
        imageObjectPosition = imageLandscape.objectPosition;
    }
    const blurDataUrl =
        orientation === 'portrait'
            ? getImageData(imagePortrait.src).blurDataUrl
            : getImageData(imageLandscape.src).blurDataUrl;

    React.useEffect(() => {
        updateOrientation();

        const throttledUpdate = throttle(updateOrientation, 200);
        global.window.addEventListener('resize', throttledUpdate);

        return () => global.window.removeEventListener('resize', throttledUpdate);
    }, []);

    return (
        <div
            className={className}
            style={{
                ['--aspect-ratio' as string]: aspectRatio,
            }}
        >
            <div
                className="hero-image__media"
                style={
                    blurDataUrl
                        ? {
                              backgroundImage: `url("${blurDataUrl}")`,
                              backgroundSize: 'cover',
                              backgroundPosition: imageObjectPosition,
                          }
                        : undefined
                }
                ref={divRef}
            >
                {orientation ? (
                    <Image
                        src={imageSrc}
                        alt=""
                        fill={true}
                        priority={blurDataUrl ? false : priority}
                        style={{
                            objectFit: 'cover',
                            objectPosition: imageObjectPosition,
                            opacity: blurDataUrl && !isLoaded ? 0 : 1,
                            transition: 'opacity 0.5s',
                        }}
                        onLoad={handleImageLoaded}
                        sizes={`calc(100vw - ${scrollbarWidth})`}
                    />
                ) : null}
            </div>
            {overlay ? <div className="hero-image__overlay">{overlay}</div> : null}
        </div>
    );
};

export default HeroImage;
