import React, { ElementType, CSSProperties } from 'react';

import classNames from 'classnames';

export enum TextType {
    H1 = 'header-1',
    H2 = 'header-2',
    H3 = 'header-3',
    P1 = 'p-1',
    P2 = 'p-2',
    P3 = 'p-3',
    ACTION_LARGE = 'action-large',
    ACTION_MEDIUM = 'action-medium',
    ACTION_SMALL = 'action-small',
}

export type TextProps = {
    inline?: boolean;
    type?: TextType;
    noWrap?: boolean;
    isExtended?: boolean;
    isInherited?: boolean;
    extraClass?: string;
    color?: 'default' | 'inverse' | 'brand';
    style?: any;
    html?: string;
    htmlEditor?: React.ReactNode;
    isResponsive?: boolean;
    children?: React.ReactNode;
    as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div';
};

const Text: React.FC<React.PropsWithChildren<TextProps>> = (props) => {
    const {
        inline,
        type,
        color = 'default',
        noWrap,
        isExtended,
        isInherited,
        extraClass,
        style,
        html,
        htmlEditor,
        isResponsive,
        as = 'div',
    } = props;

    const classnames = {
        block: classNames(
            typeof type !== 'undefined' && type,
            {
                'has-no-wrap': noWrap,
                'is-extended': isExtended,
                'is-inherited': isInherited,
                [extraClass as string]: extraClass,
                'is-responsive': isResponsive,
                [`color-${color}`]: color,
            },
            color && typeof color === 'string' ? color : null,
        ),
    };

    const TagName = as as ElementType;
    let blockStyle: CSSProperties = {};
    if (inline && as !== 'span') {
        blockStyle = {
            display: 'inline',
        };
    }

    if (!inline && as === 'span') {
        blockStyle = {
            display: 'block',
        };
    }

    return (
        <TagName
            className={classnames.block}
            style={{ ...blockStyle, ...style }}
            dangerouslySetInnerHTML={
                html && !htmlEditor
                    ? {
                          __html: html,
                      }
                    : undefined
            }
        >
            {htmlEditor || props.children}
        </TagName>
    );
};

export default Text;
