import React, { FunctionComponent, useState } from 'react';

import { common } from 'components/lexemes';
import { KeenSliderPlugin, useKeenSlider } from 'keen-slider/react';
import { LiveAnnouncerMessageAssistant } from 'lib/react-aria-live';

import A11yVisuallyHidden from 'components/a11y/a11y-visually-hidden';
import Button from 'components/button/button';
import Icon from 'components/icon/icon';

type Props = {
    children?: React.ReactNode | React.ReactNode[];
    id: string;
    ariaLabel?: string;
};

const ResizePlugin: KeenSliderPlugin = (slider) => {
    const observer = new ResizeObserver(function () {
        slider.update();
    });

    slider.on('created', () => {
        observer.observe(slider.container);
    });
    slider.on('destroyed', () => {
        observer.unobserve(slider.container);
    });
};

const Carousel: FunctionComponent<Props> = ({ children, id, ariaLabel }) => {
    const slides = React.Children.toArray(children).filter(Boolean);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [message, setMessage] = useState<undefined | string>(undefined);
    const [sliderRef, instanceRef] = useKeenSlider(
        {
            slideChanged(slider) {
                setCurrentSlide(slider.track.details.rel);

                const currentChild = slides[slider.track.details.rel];
                let messageToAnnounce = common.lxA11yCarouselSlide(
                    slider.track.details.rel + 1,
                    slides.length,
                );
                if (React.isValidElement(currentChild)) {
                    const currentChildTitle = currentChild.props.title;
                    messageToAnnounce = `${messageToAnnounce}, ${currentChildTitle}`;
                }

                setMessage(messageToAnnounce);
            },
            created() {
                setLoaded(true);
            },
            loop: true,
            selector: '.carousel__slide',
            slides: {
                origin: 'center',
                perView: 1,
                spacing: 16,
            },
        },
        [ResizePlugin],
    );

    return (
        <div
            className="carousel"
            role="region"
            aria-roledescription={common.lxA11yCarouselDescription}
            aria-label={ariaLabel}
        >
            <LiveAnnouncerMessageAssistant message={message} />

            <div className="carousel__content">
                {loaded && instanceRef.current && (
                    <>
                        <div className="carousel__nav carousel__nav--prev">
                            <Button
                                icon={<Icon name="generic-chevron-back" />}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    instanceRef.current?.prev();
                                }}
                                labelText={common.lxA11yCarouselNavPrev}
                                isCircle={true}
                                ariaControls={`${id}_slides`}
                            />
                        </div>

                        <div className="carousel__nav carousel__nav--next">
                            <Button
                                icon={<Icon name="generic-chevron-forward" />}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    instanceRef.current?.next();
                                }}
                                labelText={common.lxA11yCarouselNavNext}
                                isCircle={true}
                                ariaControls={`${id}_slides`}
                            />
                        </div>
                    </>
                )}

                <ul ref={sliderRef} className="carousel__slides" id={`${id}_slides`} role="group">
                    {slides.map((card, index) => {
                        return (
                            <li
                                className="carousel__slide"
                                key={index}
                                // @ts-ignore
                                inert={currentSlide !== index ? '' : undefined}
                                aria-hidden={currentSlide !== index}
                                aria-roledescription={common.lxA11yCarouselSlideDescription}
                                aria-label={`${index + 1} of ${slides.length}`}
                                role="group"
                            >
                                {React.isValidElement(card)
                                    ? React.cloneElement(card, {
                                          // @ts-ignore
                                          isDisabled: currentSlide !== index,
                                      })
                                    : card}
                            </li>
                        );
                    })}
                </ul>
            </div>

            {loaded && instanceRef.current && (
                <div className="carousel__dots">
                    {[...Array(instanceRef.current.track.details.slides.length).keys()].map(
                        (idx) => {
                            return (
                                <button
                                    key={idx}
                                    onClick={() => {
                                        instanceRef.current?.moveToIdx(idx);
                                    }}
                                    className={`carousel__dot ${
                                        currentSlide === idx ? ' is-active' : ''
                                    }`}
                                >
                                    <A11yVisuallyHidden>
                                        {common.lxA11yCarouselDot(idx + 1)}
                                    </A11yVisuallyHidden>
                                </button>
                            );
                        },
                    )}
                </div>
            )}
        </div>
    );
};

export default Carousel;
