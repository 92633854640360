import * as React from 'react';

import { footer, pageIndex } from 'components/lexemes';
import { CdnImagePath } from 'lib/cdn-image-path';
import { downloadAction, signInAction } from 'lib/seo/seo';

import {
    onAppleStoreClick,
    onClickDownloadNowCta,
    onGoogleStoreClick,
    onMenuItemClickHandler,
} from 'containers/events';

import PageSectionHeroSplash from 'components/page/sections/page-section-hero-splash';

export const LandingHeroBlockContainer: React.FC = () => {
    const props = {
        title: pageIndex.lxPageIndexHeroBlockTitle,
        backgroundImageSrcPortrait: `${CdnImagePath}/landing/assets/photos/landing-hero-portrait.jpg`,
        backgroundObjectPositionPortrait: '75% 34%',
        backgroundImageSrcLandscape: `${CdnImagePath}/landing/assets/photos/landing-hero-landscape.jpg`,
        backgroundObjectPositionLandscape: '77% 10%',
        subTitle: pageIndex.lxPageIndexHeroBlockSubTitle,
        actionText: pageIndex.lxPageIndexHeroBlockAction,
        actionUrl: downloadAction,
        actionCtaText: footer.lxFooterItemLabelSignIn(),
        actionCtaUrl: `${signInAction}&utm_content=hero-block`,
        actionCtaOnClick: () => onMenuItemClickHandler('sign-in'),
    };

    return (
        <PageSectionHeroSplash
            title={props.title}
            subTitle={props.subTitle}
            action={{
                text: props.actionText,
                href: props.actionUrl,
                onClick: onClickDownloadNowCta,
                onGoogleStoreClick,
                onAppleStoreClick,
            }}
            actionCta={{
                text: props.actionCtaText,
                href: props.actionCtaUrl,
                onClick: props.actionCtaOnClick,
            }}
            image={{
                srcPortrait: props.backgroundImageSrcPortrait,
                objectPositionPortrait: props.backgroundObjectPositionPortrait,
                srcLandscape: props.backgroundImageSrcLandscape,
                objectPositionLandscape: props.backgroundObjectPositionLandscape,
                hasFrontFilter: true,
                hasLoadPriority: true,
            }}
        />
    );
};
