import React, { FunctionComponent } from 'react';

import Card, { CardProps } from 'components/card/card';
import Cards from 'components/cards/cards';
import { CtaBox, CtaBoxContent, CtaBoxHeader } from 'components/cta-box';
import PageSection, { PageSectionProps } from 'components/page/page-section';

export type PageSectionCardsProps = {
    title: string;
    cards?: CardProps[];
    color?: PageSectionProps['color'];
    styling?: CardProps['styling'];
};

const PageSectionCards: FunctionComponent<PageSectionCardsProps> = ({
    title,
    cards = [],
    color,
    styling,
}) => {
    return (
        <PageSection padding="block" inner={true} color={color}>
            <CtaBox align="start">
                <CtaBoxHeader tag="h2">{title}</CtaBoxHeader>
                <CtaBoxContent width="stretch">
                    <Cards>
                        {cards.map((card, index) => (
                            <Card
                                imageSrc={card.imageSrc}
                                onClick={card.onClick}
                                href={card.href}
                                title={card.title}
                                text={card.text}
                                alt={card.alt}
                                key={`card-${index}`}
                                titleTag={card.titleTag}
                                styling={styling}
                            />
                        ))}
                    </Cards>
                </CtaBoxContent>
            </CtaBox>
        </PageSection>
    );
};

export default PageSectionCards;
