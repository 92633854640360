import React, { FunctionComponent } from 'react';

import { pageIndex } from 'components/lexemes';
import { CdnImagePath } from 'lib/cdn-image-path';

import { onClickModeBFFCta, onClickModeBizzCta, onClickModeDateCta } from 'containers/events';

import PageSectionCards from 'components/page/sections/page-section-cards';

export const LandingBlockTwoContainer: FunctionComponent = () => {
    const props = {
        title: pageIndex.lxPageIndexTwoBlockTitle,
        items: [
            {
                imageSrc: `${CdnImagePath}/landing/assets/photos/landing-section-2-1.jpg`,
                title: pageIndex.lxPageIndexTwoBlockOneItemTitle,
                text: pageIndex.lxPageIndexTwoBlockOneItemText,
                href: 'https://bumble.com/date',
                onClick: onClickModeDateCta,
            },
            {
                imageSrc: `${CdnImagePath}/landing/assets/photos/landing-section-2-2.jpg`,
                title: pageIndex.lxPageIndexTwoBlockTwoItemTitle,
                text: pageIndex.lxPageIndexTwoBlockTwoItemText,
                href: 'https://bumble.com/bff',
                onClick: onClickModeBFFCta,
            },
            {
                imageSrc: `${CdnImagePath}/landing/assets/photos/landing-section-2-3.jpg`,
                title: pageIndex.lxPageIndexTwoBlockThreeItemTitle,
                text: pageIndex.lxPageIndexTwoBlockThreeItemText,
                href: 'https://bumble.com/bizz',
                onClick: onClickModeBizzCta,
            },
        ],
    };

    return <PageSectionCards title={props.title} cards={props.items} />;
};
