import React from 'react';

import cx from 'classnames';

export enum ColumnsSize {
    'fluid',
    '1/1',
    '1/2',
    '1/3',
    '2/3',
}

const mapSizeToClasses = {
    [ColumnsSize.fluid]: '',
    [ColumnsSize['1/1']]: 'columns__item',
    [ColumnsSize['1/2']]: 'columns__item--half',
    [ColumnsSize['1/3']]: 'columns__item--one-third',
    [ColumnsSize['2/3']]: 'columns__item--two-third',
};

type ColumnsPropsType = {
    children?: React.ReactNode;
    size?: ColumnsSize;
    center?: boolean;
    end?: boolean;
    columnsItemGap?: string | number;
    verticalAlign?: 'center' | 'end' | 'stretch';
};

function ColumnsItem(props: ColumnsPropsType) {
    const {
        children,
        size = ColumnsSize['1/2'],
        center,
        end,
        columnsItemGap,
        verticalAlign,
    } = props;
    const classname = cx({
        columns__item: true,
        [mapSizeToClasses[size]]: true,
        'columns__item--center': center,
        'columns__item--end': end,
        [`columns__item--valign-${verticalAlign}`]: verticalAlign,
    });

    return (
        <div
            className={classname}
            style={{
                ['--columns-item-gap' as string]:
                    columnsItemGap && typeof columnsItemGap === 'number'
                        ? `${columnsItemGap}px`
                        : columnsItemGap,
            }}
        >
            {children}
        </div>
    );
}

ColumnsItem.size = ColumnsSize;

export default ColumnsItem;
