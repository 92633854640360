import React, { FunctionComponent } from 'react';

import { FooterContainer } from 'containers/common/app/footer-container';
import { HeaderContainer } from 'containers/common/app/header-container';
import { PageContainer } from 'containers/page/page-container';

import { LandingBlockFourContainer } from './landing-block-four-container';
import { LandingBlockOneContainer } from './landing-block-one-container';
import { LandingBlockThreeContainer } from './landing-block-three-container';
import { LandingBlockTwoContainer } from './landing-block-two-container';
import { LandingHeroBlockContainer } from './landing-hero-block-container';

export const LandingContainer: FunctionComponent = () => {
    return (
        <PageContainer>
            <HeaderContainer />
            <main id="main" tabIndex={-1}>
                <LandingHeroBlockContainer />
                <LandingBlockOneContainer />
                <LandingBlockTwoContainer />
                <LandingBlockThreeContainer />
                <LandingBlockFourContainer />
            </main>
            <FooterContainer />
        </PageContainer>
    );
};
