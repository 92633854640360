import * as React from 'react';

import { common } from 'components/lexemes';
import { getImageData } from 'lib/get-image-data';
import Image from 'lib/image-container';
import { MediaQueryWidth } from 'lib/use-media-query';

import A11yVisuallyHidden from 'components/a11y/a11y-visually-hidden';

const CARD_MAX_WIDTH = '609px';

export type CarouselCardProps = {
    imageSrc: string;
    onClick?: () => void;
    href: string;
    target?: '_blank';
    title: string | React.ReactNode;
    text?: string | React.ReactNode;
    alt?: string;
    isDisabled?: boolean;
};

const CarouselCard: React.FunctionComponent<CarouselCardProps> = ({
    imageSrc,
    title,
    text,
    onClick,
    href,
    target,
    alt,
    isDisabled,
}) => {
    const blurDataUrl = getImageData(imageSrc).blurDataUrl;

    return (
        <div className="carousel-card">
            <div
                className="carousel-card__image"
                style={
                    blurDataUrl
                        ? {
                              backgroundImage: `url("${blurDataUrl}")`,
                              backgroundSize: 'cover',
                          }
                        : undefined
                }
            >
                <Image
                    alt={alt || ''}
                    src={imageSrc!}
                    width={609}
                    height={406}
                    sizes={`
                        (max-width: ${MediaQueryWidth['desktop-sm']}px) 100vw,
                        (max-width: ${MediaQueryWidth['content-max']}px) 50vw,
                        ${CARD_MAX_WIDTH}
                    `}
                    style={{
                        objectFit: 'cover',
                    }}
                    placeholder={blurDataUrl ? 'blur' : undefined}
                    blurDataURL={blurDataUrl}
                />
            </div>

            <div className="carousel-card__content">
                {isDisabled ? (
                    <div className="carousel-card__title">{title}</div>
                ) : (
                    <a
                        className="carousel-card__title"
                        onClick={onClick}
                        href={href}
                        target={target}
                        rel="noreferrer"
                    >
                        {title}

                        {target === '_blank' ? (
                            <A11yVisuallyHidden>{common.lxA11yOpensInNewWindow}</A11yVisuallyHidden>
                        ) : null}
                    </a>
                )}

                <div className="carousel-card__text">{text}</div>
            </div>
        </div>
    );
};

export default CarouselCard;
