import * as React from 'react';

import cx from 'classnames';
import { common } from 'components/lexemes';
import { getImageData } from 'lib/get-image-data';
import Image from 'lib/image-container';
import { MediaQueryWidth } from 'lib/use-media-query';
import useScrollbarWidth from 'lib/use-scrollbar-width';

import A11yVisuallyHidden from 'components/a11y/a11y-visually-hidden';

const PAGE_PADDING_SMALL = '16px';
const PAGE_PADDING_MEDIUM = '64px';
const PAGE_PADDING_LARGE = '108px';
const CARD_MAX_WIDTH_1 = '420px';
const CARD_GAP = '24px';
const CARD_MIN_WIDTH = '280px';
const THREE_COLUMN_BREAKPOINT_WITHOUT_SCROLLBAR =
    parseInt(CARD_MIN_WIDTH, 10) * 3 +
    parseInt(CARD_GAP, 10) * 2 +
    parseInt(PAGE_PADDING_LARGE, 10) * 2;

export type CardProps = {
    imageSrc: string;
    onClick?: () => void;
    href?: string;
    target?: '_blank';
    title: string | React.ReactNode;
    text?: string | React.ReactNode;
    alt?: string;
    styling?: 'default' | 'filled';
    titleTag?: 'h2' | 'h3';
};

const Card: React.FunctionComponent<CardProps> = ({
    imageSrc,
    title,
    text,
    onClick,
    href,
    target,
    alt,
    styling = 'default',
    titleTag = 'div',
}) => {
    const scrollbarWidth = useScrollbarWidth(true);

    const className = cx({
        card: true,
        [`card--${styling}`]: styling,
    });

    const TitleTag = titleTag as React.ElementType;

    let titleElement;
    if (href) {
        titleElement = (
            <a
                className="card__title"
                onClick={onClick}
                href={href}
                target={target}
                rel="noreferrer"
            >
                {title}

                {target === '_blank' ? (
                    <A11yVisuallyHidden>{common.lxA11yOpensInNewWindow}</A11yVisuallyHidden>
                ) : null}
            </a>
        );
    } else if (onClick) {
        titleElement = (
            <button className="card__title" onClick={onClick}>
                {title}
            </button>
        );
    } else {
        titleElement = <TitleTag className="card__title">{title}</TitleTag>;
    }

    const blurDataUrl = getImageData(imageSrc).blurDataUrl;

    return (
        <div className={className}>
            <div
                className="card__image"
                style={
                    blurDataUrl
                        ? {
                              backgroundImage: `url("${blurDataUrl}")`,
                              backgroundSize: 'cover',
                          }
                        : undefined
                }
            >
                <Image
                    alt={alt || ''}
                    src={imageSrc!}
                    width={450}
                    height={300}
                    sizes={`
                        (max-width: calc(${CARD_MAX_WIDTH_1} + 2 * ${PAGE_PADDING_SMALL} + ${scrollbarWidth})) calc(100vw - 2 * ${PAGE_PADDING_SMALL} - ${scrollbarWidth}),
                        (max-width: ${MediaQueryWidth['tablet-sm']}px) ${CARD_MAX_WIDTH_1},
                        (max-width: ${
                            THREE_COLUMN_BREAKPOINT_WITHOUT_SCROLLBAR + parseInt(scrollbarWidth, 10)
                        }px) calc((100vw - 2 * ${PAGE_PADDING_MEDIUM} - ${CARD_GAP} - ${scrollbarWidth}) / 2),
                        (max-width: ${
                            MediaQueryWidth['tablet-lg']
                        }px) calc((100vw - 2 * ${PAGE_PADDING_MEDIUM} - 2 * ${CARD_GAP} - ${scrollbarWidth}) / 3),
                        (max-width: ${
                            MediaQueryWidth['content-max']
                        }px) calc((100vw - 2 * ${PAGE_PADDING_LARGE} - 2 * ${CARD_GAP} - ${scrollbarWidth}) / 3),
                        390px
                    `}
                    placeholder={blurDataUrl ? 'blur' : undefined}
                    blurDataURL={blurDataUrl}
                />
            </div>

            <div className="card__content">
                {titleElement}

                {typeof text === 'string' ? (
                    <div
                        className="card__text"
                        dangerouslySetInnerHTML={{
                            __html: text,
                        }}
                    />
                ) : (
                    <div className="card__text">{text}</div>
                )}
            </div>
        </div>
    );
};

export default Card;
