// this is a wrapper over LiveMessage to give more control over the component
// the most important functionality - ability to skip announcement on the first render (isSilentByDefault)

import React, { FC, useEffect, useRef, ComponentProps, useState } from 'react';
import { LiveAnnouncer, LiveMessage, LiveMessenger } from 'react-aria-live';

type LiveMessageProps = ComponentProps<typeof LiveMessage>;
type LiveMessageAssistantProps = Partial<LiveMessageProps> & {
    /**
     * If passed message shouldn't be announced after the first render
     */
    isSilentByDefault?: boolean;
};

const LiveMessageAssistant: FC<LiveMessageAssistantProps> = ({
    message,
    'aria-live': ariaLive = 'polite',
    clearOnUnmount,
    isSilentByDefault,
}) => {
    // ignoring empty messages
    const trimmedMessage = message?.trim();
    const [isSilent, setIsSilent] = useState<boolean | undefined>(isSilentByDefault);
    const prevMessageRef = useRef<string | undefined>(trimmedMessage);

    useEffect(() => {
        // additional protection in case LiveMessageAssistant is rerendered with the same props
        if (trimmedMessage && trimmedMessage !== prevMessageRef.current) {
            setIsSilent(false);
            prevMessageRef.current = trimmedMessage;
        }
    }, [trimmedMessage]);

    if (isSilent || !trimmedMessage) {
        return null;
    }

    return (
        <LiveMessage
            message={trimmedMessage}
            clearOnUnmount={clearOnUnmount}
            aria-live={ariaLive}
        />
    );
};

const LiveAnnouncerMessageAssistant: FC<LiveMessageAssistantProps> = (props) => {
    return (
        <LiveAnnouncer>
            <LiveMessageAssistant {...props} />
        </LiveAnnouncer>
    );
};

export {
    LiveAnnouncer,
    LiveMessenger,
    LiveMessage,
    // extended LiveMessage, requires to be wrapped in LiveAnnouncer
    LiveMessageAssistant,
    // extended LiveMessage wrapped in LiveAnnouncer
    LiveAnnouncerMessageAssistant,
};
