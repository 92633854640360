import React, { FunctionComponent } from 'react';

import CarouselCard, { CarouselCardProps } from 'components/carousel-card/carousel-card';
import Carousel from 'components/carousel/carousel';
import { CtaBox, CtaBoxContent, CtaBoxHeader } from 'components/cta-box';
import PageSection from 'components/page/page-section';

type Props = {
    title: string;
    cards?: CarouselCardProps[];
};

const PageSectionLandingSuccessStories: FunctionComponent<Props> = ({ title, cards = [] }) => {
    return (
        <>
            <PageSection color="orange" padding="block" inner={true}>
                <CtaBox align="start">
                    <CtaBoxHeader tag="h2">{title}</CtaBoxHeader>
                    <CtaBoxContent width="stretch">
                        <div className="page__section-expander">
                            <Carousel ariaLabel={title} id="carousel">
                                {cards.map((card, index) => (
                                    <CarouselCard
                                        imageSrc={card.imageSrc}
                                        onClick={card.onClick}
                                        href={card.href}
                                        title={card.title}
                                        text={card.text}
                                        alt={card.alt}
                                        key={`card-${index}`}
                                        target={card.target}
                                    />
                                ))}
                            </Carousel>
                        </div>
                    </CtaBoxContent>
                </CtaBox>
            </PageSection>
        </>
    );
};

export default PageSectionLandingSuccessStories;
