import React from 'react';

import { ExternalLink } from 'lib/app/external-link';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';
import usePlatform, { PlatformType } from 'lib/use-platform';

import Button, { ButtonColor } from 'components/button/button';
import { CtaBox, CtaBoxActions, CtaBoxContent, CtaBoxHeader } from 'components/cta-box';
import HeroImage from 'components/hero-image/hero-image';
import Icon, { IconName } from 'components/icon/icon';
import PageSection from 'components/page/page-section';
import P1 from 'components/typography/p-1';

type Props = {
    action: {
        onAppleStoreClick?: () => void;
        onGoogleStoreClick?: () => void;
        onClick?: () => void;
        href?: string;
        text: string;
    };
    actionCta?: {
        href?: string;
        onClick?: () => void;
        text: string;
    };
    title: string;
    subTitle: string;
    image: {
        srcPortrait: string;
        objectPositionPortrait?: string;
        srcLandscape: string;
        objectPositionLandscape?: string;
        hasFrontFilter?: boolean;
        hasLoadPriority?: boolean;
    };
};

const PageSectionHeroSplash: React.FunctionComponent<Props> = ({
    image,
    action,
    actionCta,
    title,
    subTitle,
}) => {
    const platform = usePlatform();

    let actionIcon;
    let actionUrl = action.href;
    let actionOnClick = action.onClick;
    if (platform === PlatformType.ios) {
        actionIcon = IconName.Apple;
        actionUrl = ExternalLink.APP_STORE_IOS;
        actionOnClick = action.onAppleStoreClick;
    }
    if (platform === PlatformType.android) {
        actionIcon = IconName.Android;
        actionUrl = ExternalLink.APP_STORE_ANDROID;
        actionOnClick = action.onGoogleStoreClick;
    }

    const isMobile = useMediaQuery(MediaQueryWidth['desktop-sm']);

    return (
        <PageSection>
            <HeroImage
                priority={image.hasLoadPriority}
                overlay={
                    <CtaBox>
                        <CtaBoxHeader color="brand" tag="h1">
                            {title}
                        </CtaBoxHeader>
                        {!platform && !isMobile ? (
                            <CtaBoxContent>
                                <Icon
                                    name="qr-code"
                                    color="inverse"
                                    a11yLabel="QR code to download the app"
                                />
                            </CtaBoxContent>
                        ) : null}
                        <CtaBoxActions>
                            <P1 color="brand">{subTitle}</P1>
                            <Button
                                text={action.text}
                                color={ButtonColor.INVERSE}
                                icon={actionIcon ? <Icon name={actionIcon} /> : undefined}
                                onClick={actionOnClick}
                                href={actionUrl}
                            />
                            {actionCta ? (
                                <Button
                                    text={actionCta.text}
                                    color={ButtonColor.INVERSE}
                                    onClick={actionCta.onClick}
                                    href={actionCta.href}
                                    extraClass="button--cta-ab-test"
                                    seo={{ label: 'sign-in' }}
                                />
                            ) : undefined}
                        </CtaBoxActions>
                    </CtaBox>
                }
                imagePortrait={{
                    src: image.srcPortrait,
                    objectPosition: image.objectPositionPortrait,
                }}
                imageLandscape={{
                    src: image.srcLandscape,
                    objectPosition: image.objectPositionLandscape,
                }}
                hasFrontFilter={image.hasFrontFilter}
                aspectRatio={1440 / 889}
                isFullScreen={true}
            />
        </PageSection>
    );
};

export default PageSectionHeroSplash;
